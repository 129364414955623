import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";
import { scroller } from 'react-scroll';
import { withDeviceDetection } from '../utils/HOCs/withDeviceDetection';
import { RoundButton } from '../';

import {
    AnimatedBorder,
    AnimatedBorderContainer,
    ButtonContainer,
    Description,
    OuterContainer,
    Img,
    ImgOverlay,
    Title,
} from './mainHero.styles';

const MainHeroComponent = ({intl, isMobile, iSecondaryHero}) => {

    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "industry.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // const data = useStaticQuery(graphql`
  // query {
  //   placeholderImage: allFile(
  //     filter: {
  //       relativePath: { in: ["test.jpg", "test-mobile.jpg"] }
  //     }) {
  //       edges {
  //         node {
  //           childImageSharp {
  //             fluid(maxWidth: 1980) {
  //               ...GatsbyImageSharpFluid
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // const defaultImg = data.placeholderImage.edges[1].node.childImageSharp.fluid;
  // const smallImg = data.placeholderImage.edges[0].node.childImageSharp.fluid;

  // const defaultImg = data.placeholderImage.edges[0].node.childImageSharp.fluid.src.indexOf("test-mobile.jpg") === -1
  // ? data.placeholderImage.edges[0].node.childImageSharp.fluid
  // : data.placeholderImage.edges[1].node.childImageSharp.fluid;

  // const smallImg = data.placeholderImage.edges[0].node.childImageSharp.fluid.src.indexOf("test-mobile.jpg") === -1
  // ? data.placeholderImage.edges[1].node.childImageSharp.fluid
  // : data.placeholderImage.edges[0].node.childImageSharp.fluid;

  // const img = isMobile ? smallImg : defaultImg;

  const img = data.placeholderImage.childImageSharp.fluid;

return(
    <OuterContainer>
        <Img fluid={img} iSecondaryHero={iSecondaryHero} alt={intl.formatMessage({ id: 'home.hero.alt'})} />
        <ImgOverlay />
        <AnimatedBorderContainer>
          {!isMobile && (<AnimatedBorder></AnimatedBorder>)}
          <Title>{intl.formatMessage({ id: 'home.hero.title'})} </Title>
          <Description>{intl.formatMessage({ id: 'home.hero.desc'})}</Description>
        </AnimatedBorderContainer>
        {!isMobile && 
          (<ButtonContainer>
            <RoundButton 
              text={intl.formatMessage({ id: 'home.hero.cta'})}
              onClick={() => {
                scroller.scrollTo(`services`, {
                  duration: 400,
                  offset: -100,
                  smooth: 'easeOutExpo'
                })
              }}
            />
          </ButtonContainer>)
        }
    </OuterContainer>
    );
};

export const MainHero = withDeviceDetection()(injectIntl(MainHeroComponent));