import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { colors } from '../../styles';
import { H1 } from '../../styledComponents';
import { CTA as CTAComponent } from '../cta/cta';
import { Link as LinkComponent } from 'gatsby-plugin-intl';

export const OuterContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const Title = styled(H1)`
    position: absolute;
    top: 20%;
    display: inline-table;
    border-bottom: 6px solid ${colors.brandSecondary};
    white-space: nowrap;
    color: white;
    z-index: 1;
    font-size: 100px !important;
    font-weight: bold;
`;

export const Link = styled(LinkComponent)`
    position: absolute;
    bottom: 20%;
    text-decoration: none;
`;

export const CTA = styled(CTAComponent)`
    color: white;

    p {
        font-size: 20px;
    }

    svg {
        width: 30px;
        height: 30px;
        fill: white;
    }
`;


export const Img = styled(ImgComponent)`
    width: 100%;
    height: 100%;
`;
