import React from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    Text
} from './roundButton.styles';

const RoundButtonComponent = ({text, ...props}) => (
    <Button {...props}>
        <Text>{text}</Text>
    </Button>
);

RoundButtonComponent.propTypes = {
	text: PropTypes.string
};

export const RoundButton = RoundButtonComponent;