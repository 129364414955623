import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { breakpoints, colors } from '../../styles';
import { H1, H2 } from '../../styledComponents';

export const OuterContainer = styled.div`
    width: 100%;
    // height: ${({ isSecondaryHero }) => ( isSecondaryHero ? '100%':'calc(100vh - 60px)')};
    height: 100%;
    max-height: ${({ isSecondaryHero }) => ( isSecondaryHero ? '100%':'calc(100vh - 60px)')};
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const Img = styled(ImgComponent)`
    width: 100%;
    height: ${({ isSecondaryHero }) => ( isSecondaryHero ? `50%` : '100%')};

    @media (min-width: ${breakpoints.tablet}px) {
        height: 100%;
    }
`;

export const TextContainer = styled.div`
    position: inherit;
    top: 0;
    right: ${({ left }) => ( left ? 'unset' : '0')};
    left: ${({ left }) => ( left ? '0' : 'unset')};
    width: 100%;
    padding: 5%;
    display: flex;
    flex-wrap: wrap;
    background: white;
    border-radius: ${({ left }) => ( left ? '0 10px 10px 0' : '10px 0 0 10px')};
    margin-top: 20px;

    @media (min-width: ${breakpoints.tablet}px) {
        position: absolute;
        width: 50%;
        margin: 0;
        top: 30%;
    }
`;

export const PrimaryTitle = styled(H1)`
    display: inline-table;
    padding-bottom: 5px;
    // border-bottom: ${({ noUnderline }) => ( noUnderline ? 'none' : `4px solid ${colors.brandSecondary}`)};
    border-bottom: none;
    // white-space: ${({ noUnderline }) => ( noUnderline ? 'wrap' : 'nowrap')};
    white-space: wrap;
    color: ${colors.brandPrimary};
    letter-spacing: 1px;
	font-weight: 700;
	font-size: 25px;
	line-height: 35px;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 28px;
		line-height: 40px;
	}

	@media (min-width: ${breakpoints.tabletBig}px) {
		font-size: 30px;
    }
    
    @media (min-width: ${breakpoints.desktop}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
		line-height: 40px;
		font-size: 34px;
        white-space: nowrap;
    }
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    border-bottom: ${({ noUnderline }) => ( noUnderline ? 'none' : `4px solid ${colors.brandSecondary}`)};
    white-space: ${({ noUnderline }) => ( noUnderline ? 'wrap' : 'nowrap')};
    color: ${colors.brandPrimary};
`;

export const Description = styled.p`
    width: 100%;
    padding-bottom: 3%;
    color: ${colors.brandPrimary};
    font-size: 16px;
`;
