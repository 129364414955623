import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { breakpoints, colors } from '../../styles';

export const TileContainer = styled.div`
    position: relative;
    max-width: 350px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 40px;
    padding: 0 8%;
    text-align: left;
    text-decoration: none;
    background: #EFEFEF;
`;

export const Img = styled(ImgComponent)`
    position: absolute;
    top: -40px;
    width: 80px;
    height: 80px;
    margin-left: 20px;
`;

export const Text = styled.p`
    padding: 10px 20px 0;
    color: ${colors.brandPrimary};
    font-size: 14px;

    @media (min-width: ${breakpoints.tablet}px) {
        font-size: 15px;
    }

    @media (min-width: ${breakpoints.tabletBig}px) {
        font-size: 16px;
    }
`;

export const Author = styled.p`
    padding: 10px;
    font-size: 12px;
    color: ${colors.brandPrimary};
    text-align: center;

    @media (min-width: ${breakpoints.tablet}px) {
        font-size: 13px;
    }

    @media (min-width: ${breakpoints.tabletBig}px) {
        font-size: 14px;
    }
`;

export const Quote = styled.div`
    width: 100%;
    font-size: 60px;
    transform: translateY(-100%);
    text-align: right;
    color: ${colors.brandPrimary};

    &:nth-child(2){
        text-align: left;
    }
`;
