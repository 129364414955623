import React from 'react';
import {
    IconContainer,
} from './iconTile.styles';


const IconTileComponent = ({ Icon, isRed,  ...props }) => {

    return(
        <IconContainer isRed={isRed} {...props}>
            <Icon />
        </IconContainer>
    );
};

export const IconTile = IconTileComponent;