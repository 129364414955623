import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';

export const OuterContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const Img = styled(ImgComponent)`
    position: inherit;
    right: ${({ isLeft }) => ( isLeft ? 'unset' : '0' )};
    left: ${({ isLeft }) => ( isLeft ? '0' : 'unset' )};
    width: 100%;
    height: 80%;

    @media (min-width: ${breakpoints.tabletBig}px) {
        position: absolute !important;
        width: 60%;
    }
`;

export const TextContainer = styled.div`
    position: inherit;
    right: ${({ isLeft }) => ( !isLeft ? 'unset' : '0' )};
    left: ${({ isLeft }) => ( !isLeft ? '0' : 'unset' )};
    width: 100%;
    padding: 5% 10% 5% 5%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    background: white;
    border-radius: ${({ isLeft }) => ( isLeft ? '10px 0 0 10px' : '0 10px 10px 0' )};
    box-shadow: none;

    @media (min-width: ${breakpoints.tabletBig}px) {
        width: 50%;
        position: absolute;
        box-shadow: 0px 4px 8px #989898;
        margin-top: 0;
    }

    @media all and (-ms-high-contrast:none) {
        margin-top: 20vh;
    }
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    color: ${colors.brandPrimary};
    white-space: wrap;
    border: none;

    @media (min-width: 1720px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
	}
`;

export const Description = styled.p`
    width: 100%;
    padding: 3% 0;
    color: ${colors.brandPrimary};
    font-size: 16px;
`;
