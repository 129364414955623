import styled from '@emotion/styled';
import { breakpoints } from '../../styles';


export const OuterContainer = styled.section`
    height: calc(100vh - 60px);
    margin-top: 50px;
    position: relative;

    @media (min-width: ${breakpoints.desktop}px) {
		margin-top: 200px;
    }
    
    & h2 {
        font-size: 24px;

        @media (min-width: ${breakpoints.desktop}px) {
            font-size: 34px;
        }
    }
`;
