import React from 'react';
import {
    Description,
    CTA,
    TileContainer,
    Title,
} from './serviceTile.styles';


const ServiceTileComponent = ({ link, title, description, cta, Icon, lastOne, ...props }) => {
    return(
            <TileContainer to={link} {...props} isActive={link !== '/'}>
                <Icon />
                <Title>{title} </Title>
                <Description>{description}</Description>
                <CTA 
                    text={cta}
                    down = {lastOne}
                    scrollDest = {lastOne ? 'contact' : ''}
                ></CTA>
            </TileContainer>
    );
};

export const ServiceTile = ServiceTileComponent;