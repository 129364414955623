import React, { useState } from "react"
import { changeLocale, injectIntl } from 'gatsby-plugin-intl';
import { OutsideClickHandler } from '../';
import {
  SwitcherContainer,
	Current,
	Triangle,
	Dropdown,
	Entry
} from './languageMenu.styles';

const LanguageMenuComponent = ({intl, top, isMainPage, ...props}) => {
  const [active, setActive] = useState(false);
  const initialLng = intl.locale ? intl.locale : 'en';
  const [language, setLanguage] = useState(initialLng);

  const setLng = (lng) => {
    changeLocale(lng);
    setLanguage(lng);
  }

  const toggle = () => {
    setActive(!active);
  }

  return(
  <OutsideClickHandler clickHandler={() => setActive(false)}>
		<SwitcherContainer active={active} onClick={toggle}>
			<Current top={top} isMainPage={isMainPage} active={active}>
				<div>{language}</div>
				<Triangle active={active} top={top} isMainPage={isMainPage} />
			</Current>
			{active && (
				<Dropdown>
          <Entry onClick={() => setLng('en')}>
            en
          </Entry>
          <Entry onClick={() => setLng('pl')}>
            pl
          </Entry>
				</Dropdown>
			)}
		</SwitcherContainer>
	</OutsideClickHandler>
  )
}

export const LanguageMenu = injectIntl(LanguageMenuComponent)