export const breakpoints = {
	mobile: 320,
	tablet: 768,
	tabletBig: 1024,
	desktop: 1480,
	desktopFull: 1920
};

export const colors = {
	brandPrimary: '#1F2574',
	brandSecondary: '#FF0000',
	textWhite: '#FFFFFF',
	textGrey: '#404040',
	whiteHovered: '#f2f2f2',
	greyBackground: '#e4e9ef'
};

export const shadows = {
	dropdown: '0 2px 30px 0 rgba(64, 64, 64, .2)',
	card: '0 20px 40px 0 rgba(64, 64, 64, .1)',
	ornament: '0 5px 10px 0 rgba(64, 64, 64, .1)'
};
