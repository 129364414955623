import { AndroidIcon  } from './android';
import { AngularIcon } from './angular';
import { DatabaseIcon } from './database';
import { JavaIcon } from './java';
import { JavascriptIcon } from './javascript';
import { DockerIcon } from './docker';

export const icons = [
    AndroidIcon,
    AngularIcon,
    DatabaseIcon,
    JavaIcon,
    JavascriptIcon,
    DockerIcon
];
