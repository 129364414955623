import styled from '@emotion/styled';
import { Element as ElementComponent } from 'react-scroll';
import { Link as LinkComponent } from 'gatsby-plugin-intl';


export const OuterContainer = styled.section`
    height: calc(100vh - 60px);
    margin: 200px 0;
    position: relative;

    @media all and (-ms-high-contrast:none) {
        margin: 0;
        margin-bottom: 200px;
    }
`;

export const Element = styled(ElementComponent)`
    width: 100%;
`;

export const Link = styled(LinkComponent)`
    width: 100%;
    text-decoration: none;
`;
