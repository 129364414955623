import styled from '@emotion/styled';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';
import { Element as ElementComponent } from 'react-scroll';
import { Link as LinkComponent } from 'gatsby';

export const OuterContainer = styled.section`
    height: auto;
    margin: 200px 0;
    position: relative;

    @media (min-width: ${breakpoints.tablet}px) {
        margin: 300px 0;
        height: calc(100vh - 60px);
	}
`;

export const Title = styled(H2)`
    position: absolute;
    top: -100px;
    left: 5%;
    display: inline-table;
    padding-bottom: 5px;
    margin-bottom: 100px;
    border-bottom: none;
    white-space: wrap;
    color: ${colors.brandPrimary};

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
        margin-bottom: 100px;
	}
`;

export const Element = styled(ElementComponent)`
    width: 100%;
`;

export const Link = styled(LinkComponent)`
    width: 100%;
    text-decoration: none;
`;