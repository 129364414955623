import React from 'react';
import { css } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";
import Carousel from 'nuka-carousel';
import { FullScreenSection } from '../';

import {
    Element,
    OuterContainer,
} from './aboutUsSection.styles';

const AboutUsSectionComponent = ({intl}) => {

  return(
      <OuterContainer>
          <Element name={`about`}>
            <StaticQuery
              query={graphql`
                query {
                  slider: allFile(
                    filter: {
                      relativePath: {
                        in: ["about-us.png", "about-us-2.jpg"]
                      }
                    }
                  ) {
                    edges {
                      node {
                        childImageSharp {
                          fluid(maxWidth: 1480) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={({ slider: { edges } }) => (
                <Carousel
                  css={css`
                    .slider-list {
                      height: calc(100% - 60vh);
                    }
                    .slide-visible {
                      z-index: 1;
                    }
                  `}
                  autoplay={true}
                  autoplayInterval={10000}
                  wrapAround={true}
                  withoutControls
                  pauseOnHover={false}
                  // slideIndex={controlIndex}
                  // afterSlide={handleSlideChange}
                  // heightMode={'current'}
                >
                  {edges.map(({ node }, i) => (
                    <FullScreenSection
                        key={i} 
                        fluid={node.childImageSharp.fluid}
                        title={intl.formatMessage({ id: `home.about.${i}.title`})}
                        desc={intl.formatMessage({ id: `home.about.${i}.desc`})}
                        alt={intl.formatMessage({ id: `home.about.${i}.alt`})}
                    />
                  ))}
                </Carousel>
              )}
          />
        </Element>
      </OuterContainer>
)};

export const AboutUsSection = injectIntl(AboutUsSectionComponent);