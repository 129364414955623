import styled from '@emotion/styled';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';

export const OuterContainer = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 100px;
    padding: 5% 5%;

    @media (min-width: ${breakpoints.tablet}px) {
        margin-top: 200px;
	}
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    border-bottom: none;
    white-space: wrap;
    color: ${colors.brandPrimary};
    margin-bottom: 50px;

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
        margin-bottom: 100px;
	}
`;

export const Button = styled.div`
    margin: 20px 50px 0;
    &:hover {
        curosr: pointer;
    }
`;

export const Controls = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 20px;

    svg {
        width: 50px;
        height: 50px;
        fill: ${colors.brandPrimary};
    }

    svg:hover {
        cursor: pointer;
    }

    ${Button}:first-child {
        svg {
            transform: rotate(180deg);
        }
    }
`;