import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import ImgComponent from 'gatsby-image';
import { breakpoints, colors } from '../../styles';
import { H1 } from '../../styledComponents';


const animeTime = '10s';
const boxWidth = 600;
const boxHeight = 170;
const clipDistance = .05;
const clipSize = boxWidth * (1 + clipDistance * 2);
const clipSize2 = boxHeight * (1 + clipDistance * 2);
const pathWidth = 3;

// const fadeIn = keyframes`
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// `;

const fadeInOverlay = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
`;

const fadeInBorder = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.4;
  }
`;

const fadeInBtn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
  }
`;


const clipMe = keyframes`
  0% {clip: rect(0px, ${clipSize}px, ${pathWidth}px, 0px); }
  25% {clip: rect(0px, ${pathWidth}px, ${clipSize2}px, 0px); }
  50% {clip: rect(${clipSize2 - pathWidth}px, ${clipSize}px, ${clipSize}px, 0px); }
  75% {clip: rect(0px, ${clipSize}px, ${clipSize2}px, ${clipSize - pathWidth}px); }
  100% {clip: rect(0px, ${clipSize}px, ${pathWidth}px, 0px); }
`;

export const Img = styled(ImgComponent)`
	  width: 100%;
    height: 100%;
    position: absolute !important;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.9;
`;

export const ImgOverlay = styled.div`
	  width: 100%;
    height: 100%;
    position: absolute !important;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0;
    animation: ${fadeInOverlay} 1.5s ease;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: ${fadeInBtn} 1.5s ease;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    transform: translateY(-10%);
`;

export const Title = styled(H1)`
    text-align: center;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-top: 0;
    margin-top: 0;
    opacity: 0;
    animation: ${fadeInBtn} 1.5s ease;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    color: white;
`;

export const Description = styled.p`
    width: 100%;
    padding: 0 5% 3%;
    text-align: center;
    // color: ${colors.brandPrimary};
    color: white;
    opacity: 0;
    animation: ${fadeInBtn} 1.5s ease;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
    font-size: 14px;

    @media (min-width: ${breakpoints.tabletBig}px) {
      font-size: 18px;
    }
`;


// const mainColor = colors.brandSecondary;

export const AnimatedBorderContainer = styled.div`
  position: relative;
  // width: ${boxWidth}px;
  width: 100%';
  padding: 0 2%';
  height: ${boxHeight}px;

  @media (min-width: ${breakpoints.tablet}px) {
    width: ${boxWidth}px;
    padding: 0;
  }
`;

export const AnimatedBorder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: ${boxWidth}px;
    height: ${boxHeight}px;
    margin: auto;
    // color: ${colors.brandPrimary};
    color: ${colors.brandSecondary};
    opacity: 0;
    animation: ${fadeInBorder} 1.5s ease;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      z-index: -1;
      margin: calc(-1 * ${clipDistance} * 100%);
      box-shadow: inset 0 0 0 ${pathWidth}px; 
      animation: ${clipMe} ${animeTime} linear infinite;
      animation-delay: 1.5s;
    }

    &::before {
      animation-delay: calc(${animeTime} * -.5 + 1.5s);
    }
`;

export const OuterContainer = styled.section`
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    @media all and (-ms-high-contrast:none) {
      flex-direction: row;

      ${AnimatedBorderContainer} {
        margin-top: 40vh;
      }
    }
`;
