import styled from '@emotion/styled';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';
import { Link as LinkComponent } from 'gatsby-plugin-intl';

export const OuterContainer = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5%;
    padding-right: 15%;
    margin-bottom: 100px;

    @media (min-width: ${breakpoints.tablet}px) {
        padding: 5% 15%;
	}
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    color: ${colors.brandPrimary};
    margin-bottom: 70px;
    font-size: 26px;
    white-space: wrap;
    border: none;

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
        font-size: 34px;
	}
`;

export const IconContainer = styled.div`
    width: 300px;
    height: 300px;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 100%;
    box-shadow: 0 4px 8px #989898;
    transform: translateY(-20%);

    svg {
        width: 150px;
        height: 150px;
        fill: ${colors.brandSecondary};
    }

    @media (min-width: ${breakpoints.desktop}px) {
        display: flex;
	}
`

export const Link = styled(LinkComponent)`
    text-decoration: none;
`;
