import React, { useState, useEffect } from "react"
import { scroller } from 'react-scroll';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";
import { LanguageMenu } from '../';
import { withScrollTopDetection } from './subcomponents/onScrollHandler';


import {
  StyledHeader,
  Link,
  Logo,
  Menu,
  MenuItem
} from './header.styles'

const HeaderComponent = ({ intl, top, isMainPage, isNotTransparent }) => {
  
  const [isMobile, setIsMobile] = useState(true);
  const breakpoint = 768;

  useEffect(() => {
    const detectMobile = () => {
			const width = window.innerWidth ||
				document.documentElement.clientWidth;
			return width < breakpoint;
		};

    
    const checkIsMobile = () => {
			const mobileDetected = detectMobile();

			if (isMobile === mobileDetected) {
				return;
			}

			setIsMobile(mobileDetected);
    };
    
      checkIsMobile()
      window.addEventListener('resize', checkIsMobile);

    return () => {
			window.removeEventListener('resize', checkIsMobile);
    };
  }, [isMobile]);

  const logo =
    useStaticQuery(graphql`
      query {
        placeholderImage: allFile(
          filter: {
            relativePath: { in: ["logo.png", "logo_white.png"] }
          }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
    `)

  const defaultLogo = logo.placeholderImage.edges[0].node.childImageSharp.fluid.src.indexOf("logo_white.png") === -1
        ? logo.placeholderImage.edges[0].node.childImageSharp.fluid
        : logo.placeholderImage.edges[1].node.childImageSharp.fluid;

  const whiteLogo = logo.placeholderImage.edges[0].node.childImageSharp.fluid.src.indexOf("logo_white.png") === -1
        ? logo.placeholderImage.edges[1].node.childImageSharp.fluid
        : logo.placeholderImage.edges[0].node.childImageSharp.fluid;
  
  return(
    <StyledHeader top={top || (isNotTransparent && isMobile)}>
      <Link to='/'>
        <Logo 
          top={top || (isNotTransparent && isMobile)} 
          fluid={
            !(top || (isNotTransparent && isMobile))
            ? whiteLogo
            : defaultLogo
          }
          alt="SIS logo"
        />
      </Link>
      {isMainPage && (
        <Menu>
          <MenuItem 
            top={top || (isNotTransparent && isMobile)} 
            isMainPage={isMainPage}
            isMobile={isMobile}
            onClick={() =>
              scroller.scrollTo(`services`, {
                duration: 400,
                offset: -100,
                smooth: 'easeOutExpo'
              })
            }
          >
            {intl.formatMessage({ id: 'header.services'})}
          </MenuItem>
          <MenuItem 
            top={top || (isNotTransparent && isMobile)}
            isMainPage={isMainPage}
            isMobile={isMobile}
            onClick={() =>
              scroller.scrollTo(`about`, {
                duration: 400,
                offset: 0,
                smooth: 'easeOutExpo'
              })
            }
          >
            {intl.formatMessage({ id: 'header.about'})}
          </MenuItem>
          <MenuItem
            top={top || (isNotTransparent && isMobile)}
            isMainPage={isMainPage}
            isMobile={isMobile}
            onClick={() =>
              scroller.scrollTo(`clients`, {
                duration: 400,
                offset: -70,
                smooth: 'easeOutExpo'
              })
            }
          >
            {intl.formatMessage({ id: 'header.clients'})}
          </MenuItem>
          <MenuItem
            top={top || (isNotTransparent && isMobile)}
            isMainPage={isMainPage}
            isMobile={isMobile}
            onClick={() =>
              scroller.scrollTo(`contact`, {
                duration: 400,
                offset: -60,
                smooth: 'easeOutExpo'
              })
            }
          >
            {intl.formatMessage({ id: 'header.contact'})}
          </MenuItem>
          <LanguageMenu top={top || (isNotTransparent && isMobile)} isMainPage={isMainPage}></LanguageMenu>
        </Menu>
      )}
      {!isMainPage && (
        <Menu>
          <LanguageMenu top={top || (isNotTransparent && isMobile)}></LanguageMenu>
        </Menu>
      )}
    </StyledHeader>
  );
};

export const Header = withScrollTopDetection() (injectIntl(HeaderComponent))
