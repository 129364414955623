import styled from '@emotion/styled';
import { colors } from '../../styles';


export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.brandPrimary};
    cursor: pointer;

    svg {
        fill: ${colors.brandPrimary};
        width: auto;
        height: 18px;
        margin: 2px 0 0;
        transform: ${({ down }) => (down ? 'rotate(90deg)' : 'unset')};
        
        @media all and (-ms-high-contrast:none) {
            display: none;
        }
    }
`;

export const Text = styled.p`
    margin: 0;
    margin-right: 5px;
    font-size: 16px;
    font-weight: bold;
`;
