import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from "gatsby-plugin-intl";
import { withDeviceDetection } from '../utils/HOCs/withDeviceDetection';
import {
    Description,
    Element,
    OuterContainer,
    ServiceTile,
    TextContainer,
    Title,
} from './serviceSection.styles';
import { icons } from '../../images/icons/services';
import { TileGroup } from '../serviceTile/serviceTile.styles';

const ServiceSectionComponent = ({intl, isMobile}) => {
    const services = Array.from(new Array(3).keys())

    return(
        <OuterContainer>
                <TextContainer isMobile={isMobile}>
                    <Title>{intl.formatMessage({ id: 'home.services.title'})} </Title>
                    <Description>{intl.formatMessage({ id: 'home.services.desc'})}</Description>
                </TextContainer>
            <Element name={`services`}>
                  <TileGroup>
                    {services.map((tileNumber, innerIndex) => (
                      <ServiceTile
                        key={`tile${innerIndex}`}
                        title={intl.formatMessage({ id: `home.services.services.${tileNumber}.name`})}
                        description={intl.formatMessage({ id: `home.services.services.${tileNumber}.desc`})}
                        cta={intl.formatMessage({ id: `home.services.services.${tileNumber}.cta`})}
                        link={intl.formatMessage({ id: `home.services.services.${tileNumber}.link`})}
                        Icon={icons[tileNumber]}
                        lastOne = {tileNumber === services.length - 1}
                      />
                    ))}
                  </TileGroup>
            </Element>
        </OuterContainer>
    );
};

ServiceSectionComponent.propTypes = {
	t: PropTypes.func
};

export const ServiceSection = withDeviceDetection()(injectIntl(ServiceSectionComponent));