import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    Author,
    Img,
    Text,
    TileContainer,
    Quote,
} from './recommendationTile.styles';

const RecommendationTileComponent = ({text, author, ...props}) => {

    const data = useStaticQuery(graphql`
        query {
        placeholderImage: file(relativePath: { eq: "recommendation.png" }) {
            childImageSharp {
                fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `)

    return(
        <TileContainer {...props}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="Recommendation" />
            <Quote>,,</Quote>
            <Text>{text}</Text>
            <Quote>,,</Quote>
            <Author>{author}</Author>
        </TileContainer>
    );
};

export const RecommendationTile = RecommendationTileComponent;