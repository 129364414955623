import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';

export const OuterContainer = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 100px;
    padding: 5% 5%;

    @media (min-width: ${breakpoints.tablet}px) {
        margin-top: 300px;
	}
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    color: ${colors.brandPrimary};
    white-space: wrap;
    border: none;

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
	}
`;

export const Description = styled.p`
    width: 100%;
    padding-bottom: 3%;
    color: ${colors.brandPrimary};
    font-size: 16px;
    margin-bottom: 70px;
`;

export const Img = styled(ImgComponent)`
    width: 50%;
    padding-left: 5%;
`;