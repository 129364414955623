import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { breakpoints, colors } from '../../styles';

export const OuterContainer = styled.div`
    width: 100%;
    height: auto;
    background: ${colors.greyBackground};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 1%;

    @media (min-width: ${breakpoints.tabletBig}px) {
        height: 150px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 1%;
    }
`;

export const Logo = styled(ImgComponent)`
	width: 200px;
    height: auto;
    transition: width .3s ease;
`;

export const TextContainer = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 12px;
    color: ${colors.brandPrimary};
    font-weight: bold;
    width: 100%;
    
    @media (min-width: ${breakpoints.tablet}px) {
        font-size: 14px;
    }

    @media (min-width: ${breakpoints.tabletBig}px) {
        text-align: right;
        width: auto;
        justify-content: flex-end;
    }
`;

export const IconsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: ${colors.brandPrimary};
    margin: 40px 0 40px -20px;

    @media (min-width: ${breakpoints.tabletBig}px) {
        justify-content: space-between;
        margin: 0;
        width: auto;
    }

    svg {
        fill: ${colors.brandPrimary};
        opacity: 0.7;
        width: 40px;
        height: 40px;
        margin: 0 20px;

    }
`;
