import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';
import { Element as ElementComponent } from 'react-scroll';

export const OuterContainer = styled.section`
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5% 5%;
    margin: 250px 0 150px;
    justify-content: center;

    @media (min-width: ${breakpoints.tablet}px) {
        margin: 100px 0 300px;
        
    }
    
    @media all and (-ms-high-contrast:none) {
        margin: 400px 0 300px;
    }
`;

export const Img = styled(ImgComponent)`
    display: none;
    width: 100%;
    // position: absolute !important;
    top: 0;
    left: 0;
    // z-index: -1;

    @media (min-width: ${breakpoints.tabletBig}px) {
        display: inherit;
    }
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    margin-bottom: 100px;
    color: ${colors.brandPrimary};
    white-space: wrap;
    border: none;

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
	}
`;

export const Description = styled.p`
    width: 100%;
    padding: 3% 0;
    color: ${colors.brandPrimary};
    font-size: 22px;
    font-weight: bold;
`;

export const ImgContainer = styled.div`
    width: 100%;
    position relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

export const ContactHalf = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: ${colors.brandPrimary};
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    padding: 5%;

    @media (min-width: ${breakpoints.tablet}px) {
        width: 50%;
        height: 100%;
        font-size: 20px;
    }
`

export const ContactContainer = styled.div`
    width: 100%;
    background: rgba(48,56,71,0.15);
    box-shadow: 0 8px 10px #989898;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    opacity: 0.8;

    @media (min-width: ${breakpoints.tablet}px) {
        width: 90%;
    }

    ${ContactHalf}:first-child {
        border-right: none;
        border-bottom: 4px solid white;

        @media (min-width: ${breakpoints.tablet}px) {
            border-right: 4px solid white;
            border-bottom: none;
        }
    }
`

export const Email = styled.div`
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    color: ${colors.brandPrimary};
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 30px;

    @media (min-width: ${breakpoints.tabletBig}px) {
        position: absolute;
        width: 50%;
        height: 100%;
        text-align: center;
        justify-content: center;
        margin: 0;
    }
`;

export const TelNo = styled.div`
    position: relative;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    color: ${colors.brandPrimary};
    text-align: left;
    font-size: 18px;
    font-weight: bold;

    @media (min-width: ${breakpoints.tabletBig}px) {
        position: absolute;
        width: 50%;
        height: 100%;
        text-align: center;
        justify-content: center;
    }
`;


export const Element = styled(ElementComponent)`
    width: 100%;
`;

