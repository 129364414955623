import styled from '@emotion/styled';
import { Link as LinkComponent } from 'gatsby-plugin-intl';
import ImgComponent from 'gatsby-image';
import { colors } from '../../styles';

export const StyledHeader = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: ${({ top }) => ( !top ? 'auto' : '60px')};
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ top }) => ( !top ? 'transparent' : 'white')};
    box-shadow: ${({ top }) => ( !top ? 'none' : '0 4px 8px #989898')};
`;

export const Logo = styled(ImgComponent)`
	  width: ${({ top }) => ( !top ? '150px' : '100px')};
    height: auto;
    transition: width .3s ease;
`;

export const Menu = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const MenuItem = styled.div`
    display: ${({ isMobile }) => ( !isMobile ? 'initial' : 'none')};
    position: relative;
    color: ${({ top }) => ( top ? colors.brandPrimary : 'white')};
    font-size: ${({ top }) => ( !top ? '19px' : '15px')};
    font-weight: bold;
    margin: 0 20px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 0; height: 3px;
      display: block;
      margin-top: 5px;
      right: 0;
      // background: ${colors.brandPrimary};
      background: ${({ top }) => ( top ? colors.brandPrimary : 'white')};
      transition: width .2s ease;
      -webkit-transition: width .2s ease;
    }

    &:hover {
      &:after {
        width: 100%;
        left: 0;
        background: ${({ top }) => ( top ? colors.brandPrimary : 'white')};
        // background: ${colors.brandPrimary};
      }
    }
`;

export const Link = styled(LinkComponent)`
    color: ${colors.brandPrimary};
    text-decoration: none;
`;
