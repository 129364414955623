import React, { useState } from 'react';
import Carousel from 'nuka-carousel';
import { injectIntl } from "gatsby-plugin-intl";
import { IconTile } from '../';
import { icons } from '../../images/icons/technologies';
import {
    Description,
    OuterContainer,
    Title,
} from './technologySection.styles';

const TechnologySectionComponent = ({intl}) => {

    const [controlIndex, setControlIndex] = useState(0);

	const handleSlideChange = i => {
        setControlIndex(i);
    };
    const iconsArray = Array.from(new Array(6).keys())

    return(
        <OuterContainer>
            <Title>{intl.formatMessage({ id: `home.technology.title`})}</Title>
            <Description>{intl.formatMessage({ id: `home.technology.desc`})}</Description>
            <Carousel
                // css={css`
                // 	.slide-visible:nth-child(2n+1) {                        
                //         svg {
                //             fill: red;
                //         }
                //     }
                // `}
                autoplay
                autoplayInterval={2000}
                slideIndex={controlIndex}
                afterSlide={handleSlideChange}
                pauseOnHover={false}
                wrapAround
                withoutControls
                slidesToShow={5}
                cellAlign="center"
                // slideIndex={controlIndex}
                // afterSlide={handleSlideChange}
                // heightMode={'current'}
            >
                {iconsArray.map((iconNumber, innerIndex) => (
                    <IconTile
                        key={innerIndex}
                        Icon={icons[iconNumber]}
                        isRed={innerIndex === controlIndex}
                    />
                ))}
            </Carousel>
        </OuterContainer>
    );
};

export const TechnologySection = injectIntl(TechnologySectionComponent);