import styled from '@emotion/styled';
import { breakpoints, colors } from '../styles';

export const H1 = styled.h1`
	letter-spacing: 1px;
	font-size: 35px;
	color: ${colors.brandPrimary};
	font-weight: normal;

	@media (min-width: ${breakpoints.tabletBig}px) {
		font-size: 40px;
	}
`;

export const H2 = styled.h2`
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 25px;
	line-height: 35px;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 30px;
		line-height: 40px;
	}

	@media (min-width: ${breakpoints.tabletBig}px) {
		font-size: 34px;
	}
`;

export const H3 = styled.h3`
	letter-spacing: 1px;
	font-size: 22px;
	font-weight: 700;
`;

export const H4 = styled.h4`
	letter-spacing: 1px;
	font-size: 20px;
	font-weight: 700;
`;

export const H5 = styled.h5`
	letter-spacing: 1px;
	font-weight: 700;
`;
