import React, { useState } from 'react';
import Carousel from 'nuka-carousel';
import { injectIntl } from "gatsby-plugin-intl";
import { withDeviceDetection } from '../utils/HOCs/withDeviceDetection';
import { Element } from 'react-scroll';
import { RecommendationTile } from '../';
import {
    Button,
    Controls,
    OuterContainer,
    Title,
} from './recommendationSection.styles';
import { RightArrowIcon } from '../../images/icons'

const RecommendationSectionComponent = ({intl, isMobile}) => {

    const [controlIndex, setControlIndex] = useState(0);

	const handleSlideChange = i => {
        setControlIndex(i);
    };
    const elementsArray = Array.from(new Array(4).keys())

    return(
        <OuterContainer>
            <Element name='description'>
                <Title>{intl.formatMessage({ id: `clients.recommendation.title`})}</Title>
            </Element>
            <Carousel
                    autoplay
                    autoplayInterval={4000}
                    slideIndex={controlIndex}
                    afterSlide={handleSlideChange}
                    pauseOnHover={false}
                    wrapAround
                    withoutControls
                    slidesToShow={isMobile ? 1:3}
                    cellAlign="center"
                >
                    {elementsArray.map((innerIndex) => (
                        <RecommendationTile
                            key={innerIndex}
                            text={intl.formatMessage({ id: `clients.recommendations.${innerIndex}.text`})}
                            author={intl.formatMessage({ id: `clients.recommendations.${innerIndex}.author`})}
                        />
                    ))}
                </Carousel>
                <Controls>
                    <Button onClick={() => {
                        setControlIndex(controlIndex - 1)
                    }}>
                        <RightArrowIcon />
                    </Button>
                    <Button onClick={() => {
                        setControlIndex(controlIndex + 1)
                    }}>
                        <RightArrowIcon />
                    </Button>
                </Controls>
        </OuterContainer>
    );
};

export const RecommendationSection = withDeviceDetection()(injectIntl(RecommendationSectionComponent));