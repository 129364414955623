import React from 'react';
import { throttle } from 'lodash';

export const withScrollTopDetection = () => BaseComponent => {
	class OnScrollHandler extends React.PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				top: typeof window === 'undefined' ? false : window.scrollY > 5,
			};
		}

		componentDidMount() {
			window.addEventListener('scroll', this.handleScroll);
		}

		componentWillUnmount() {
			window.removeEventListener('scroll', this.handleScroll);
		}

		handleScroll = throttle(
			() => this.setState({ top: window.pageYOffset > 5 }),
			200
		);

		render() {
			return <BaseComponent {...this.props} top={this.state.top} />;
		}
	}

	return OnScrollHandler;
};
