import React from 'react';
import { css } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";
import Carousel from 'nuka-carousel';
import { FullScreenSection } from '../';

import {
    Link,
    OuterContainer,
    Title,
} from './vwProjectsSection.styles';

const VwProjectsSectionComponent = ({intl}) => {

  return(
      <OuterContainer>
          <Title>{intl.formatMessage({ id: `clients.project.title`})}</Title>
          <StaticQuery
            query={graphql`
              query {
                slider: allFile(
                  filter: {
                    relativePath: {
                      in: ["meeting-app.png" , "mobile-alarm-app.png"]
                    }
                  }
                ) {
                  edges {
                    node {
                      childImageSharp {
                        fluid(maxWidth: 1480) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({ slider: { edges } }) => (
              <Carousel
                css={css`
                  .slider-list {
                    height: calc(100% - 60vh);
                  }
                  .slide-visible {
                    z-index: 1;
                  }
                `}
                autoplay={true}
                autoplayInterval={6000}
                wrapAround={true}
                withoutControls
                pauseOnHover={false}
                // slideIndex={controlIndex}
                // afterSlide={handleSlideChange}
                // heightMode={'current'}
              >
                {edges.map(({ node }, i) => (
                  <Link to={intl.formatMessage({ id: `clients.projects.${i}.link`})}>
                    <FullScreenSection
                        key={i} 
                        fluid={node.childImageSharp.fluid}
                        title={intl.formatMessage({ id: `clients.projects.${i}.title`})}
                        desc={intl.formatMessage({ id: `clients.projects.${i}.desc`})}
                        alt={intl.formatMessage({ id: `clients.projects.${i}.alt`})}
                        cta={intl.formatMessage({ id: `clients.projects.${i}.cta`})}
                        left={i===0}
                    />
                  </Link>
                ))}
              </Carousel>
            )}
        />
      </OuterContainer>
)};

export const VwProjectsSection = injectIntl(VwProjectsSectionComponent)