import * as React from 'react';

export const withDeviceDetection = (breakpoint = 768) => Base =>
	class extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				isMobile: typeof window === 'undefined'
					? false : this.detectMobile(),
			};
		}

		componentDidMount() {
			this.setIsMobile();
			window.addEventListener('resize', this.setIsMobile);
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.setIsMobile);
		}

		setIsMobile = () => {
			const { isMobile } = this.state;
			const mobileDetected = this.detectMobile();

			if (isMobile === mobileDetected) {
				return;
			}

			this.setState({ isMobile: mobileDetected });
		};

		detectMobile = () => {
			const width = window.innerWidth ||
				document.documentElement.clientWidth;
			return width < breakpoint;
		};

		render() {
			return <Base {...this.props} isMobile={this.state.isMobile} />;
		}
	};
