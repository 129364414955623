import styled from '@emotion/styled';
import { breakpoints } from '../../styles';
import { FadeInSection as FadeInSectionComponent } from '../';
import { Element as ElementComponent } from 'react-scroll';

export const OuterContainer = styled.section`
    height: auto;
    margin-top: 100px;
    position: relative;

    @media (min-width: ${breakpoints.tablet}px) {
        margin-top: 300px;
	}
`;

export const FadeInSection = styled(FadeInSectionComponent)`
    height: 100%;
    width: 100%
`;

export const Element = styled(ElementComponent)`
    width: 100%;
`;
