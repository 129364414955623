import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";

import { ImageSection } from '../';

import {
    Element,
    Link,
    OuterContainer,
} from './clientSection.styles';

const ClientSectionComponent = ({intl}) => {

    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "clients.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

return(
  <Element name={`clients`}>
    <OuterContainer>
      {/* <Link to='/clients'> */}
        <ImageSection
            title={intl.formatMessage({ id: 'home.clients.title'})}
            desc={intl.formatMessage({ id: 'home.clients.desc'})}
            // cta={intl.formatMessage({ id: 'home.clients.cta'})}
            alt={intl.formatMessage({ id: 'home.clients.alt'})}
            fluid={data.placeholderImage.childImageSharp.fluid} 
        />
      {/* </Link> */}
    </OuterContainer>
  </Element>
)};

export const ClientSection = injectIntl(ClientSectionComponent);