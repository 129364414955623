import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";
import {
    CTA,
    Link,
    OuterContainer,
    Img,
    Title,
} from './page404.styles';

const Page404Component = ({intl}) => {

    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return(
        <OuterContainer>
            <Title>{intl.formatMessage({ id: '404.title'})}</Title>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} alt={intl.formatMessage({ id: '404.alt'})}/>
            <Link to={intl.formatMessage({ id: '404.link'})}>
                <CTA text={intl.formatMessage({ id: '404.cta'})}></CTA>
            </Link>
        </OuterContainer>
    );
};

export const Page404 = injectIntl(Page404Component);