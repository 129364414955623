import React from 'react';
import { injectIntl } from "gatsby-plugin-intl";

import {
    Description,
    Element,
    OuterContainer,
    Title,
} from './serviceDescriptionSection.styles';

const ServiceDescriptionSectionComponent = ({intl, page}) => {

    return(
        <OuterContainer>
            <Element name={`description`}>
                <Title>{intl.formatMessage({ id: `${page}.service.title`})}</Title>
                <Description>{intl.formatMessage({ id: `${page}.service.desc`})}</Description>
            </Element>
        </OuterContainer>
    );
};

export const ServiceDescriptionSection = injectIntl(ServiceDescriptionSectionComponent);