import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";
import {
    Logo,
    OuterContainer,
    TextContainer,
    IconsContainer,
} from './footer.styles';

import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from '../../images/icons';

const FooterComponent = ({ intl }) => {

    const logo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

return(
    <footer>
        <OuterContainer>
            <Logo fluid={logo.placeholderImage.childImageSharp.fluid} />
            <IconsContainer>
              <a 
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/Smart-Industrial-Solutions-104718717781665">
                <FacebookIcon />
              </a>
              <a 
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/sisindustrials"
              >
                <LinkedinIcon />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/sisindustrials"
              >
                <TwitterIcon />
              </a>
              <a 
                rel="noopener noreferrer"
                target="_blank"          
                href="https://www.instagram.com/sis_industrials/"
              >
                <InstagramIcon />
              </a>
            </IconsContainer>
            <TextContainer>
                {intl.formatMessage({ id: 'footer.contact'})}: <br></br>
                {intl.formatMessage({ id: 'footer.email'})} <br></br>
                {intl.formatMessage({ id: 'footer.tel1'})} <br></br>
                {intl.formatMessage({ id: 'footer.tel2'})} <br></br>
            </TextContainer>
        </OuterContainer>
    </footer>
)};

export const Footer = injectIntl(FooterComponent);