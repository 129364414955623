import styled from '@emotion/styled';
import { breakpoints, colors } from '../../styles';


export const IconContainer = styled.div`
    text-align: center;
    svg {
        fill: ${({ isRed }) => ( isRed ? colors.brandSecondary : colors.brandPrimary )};
        transform: ${({ isRed }) => ( isRed ? 'scale(1.2)' : 'scale(1)' )};
        width: 40px;
        height: 40px;

        @media (min-width: ${breakpoints.tablet}px) {
            width: 60px;
            height: 60px;
        }
    }
`;