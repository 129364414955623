import styled from '@emotion/styled';

export const OuterContainer = styled.section`
    height: 100vh;
    width: 100%;

    img {
      object-position: top center !important;
    }
`;
