import React from 'react';
import PropTypes from 'prop-types';

export class OutsideClickHandler extends React.PureComponent {
	constructor(props) {
		super(props);

		this.ref = React.createRef();
	}

	componentDidMount() {
		window.addEventListener('click', this.handleClick);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.handleClick);
	}

	handleClick = event => {
		const { clickHandler } = this.props;
		const { target } = event;

		if (target !== this.ref.current && !this.ref.current.contains(target)) {
			clickHandler(event);
		}
	};

	render() {
		const { children } = this.props;
		return <children.type ref={this.ref} {...children.props} />;
	}
}

OutsideClickHandler.propTypes = {
	clickHandler: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired
};
