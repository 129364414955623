import styled from '@emotion/styled';
import ImgComponent from 'gatsby-image';
import { ServiceTile as ServiceTileComponent } from '../';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';
import { FadeInSection as FadeInSectionComponent } from '../';
import { Element as ElementComponent } from 'react-scroll';

export const OuterContainer = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5% 5%;
    margin-top: 50px;

    @media (min-width: ${breakpoints.tablet}px) {
        margin-top: 150px;
	}
`;

export const Img = styled(ImgComponent)`
    display: none;
    width: 50%;
    padding-left: 5%;

    @media (min-width: ${breakpoints.tabletBig}px) {
		// display: inherit;
	}
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    color: ${colors.brandPrimary};
    white-space: wrap;
    border: none;

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
	}
`;

export const Description = styled.p`
    width: 100%;
    padding-bottom: 3%;
    color: ${colors.brandPrimary};
    font-size: 16px;

    @media (min-width: ${breakpoints.tabletBig}px) {
		width: 70%;
	}
`;

export const TextContainer = styled.div`
    width: 100%;
    padding-right: 5%;
    transform: translateY(30%);

    @media (min-width: ${breakpoints.tabletBig}px) {
		// width: 50%;
	}
`;

export const FadeInSection = styled(FadeInSectionComponent)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ServiceTile = styled(ServiceTileComponent)`
`;

export const Element = styled(ElementComponent)`
    width: 100%;
    margin-top: 50px;
`;