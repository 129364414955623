import React, {useState, useEffect} from 'react';
import { FadeInSectionStyled } from './fadeInSection.styles';

const FadeInSectionComponent = ({children, ...props}) => {
    const [isVisible, setVisible] = useState(true);
    const domRef = React.useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => observer.unobserve(domRef.current);
    }, []);

    return (
      <FadeInSectionStyled
        isVisible={isVisible}
        ref={domRef}
        {...props}
      >
        {children}
      </FadeInSectionStyled>
    );
  }

  export const FadeInSection = FadeInSectionComponent
  