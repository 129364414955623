import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";

import { ImageSection } from '../';

import {
    OuterContainer,
} from './sfmImageSection.styles';

const SFMImageSectionComponent = ({intl}) => {

    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "meeting-app.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

return(
    <OuterContainer>
        <ImageSection
            title={intl.formatMessage({ id: 'meeting-app.sfm.title'})}
            desc={intl.formatMessage({ id: 'meeting-app.sfm.desc'})}
            alt={intl.formatMessage({ id: 'meeting-app.sfm.alt'})}
            fluid={data.placeholderImage.childImageSharp.fluid} 
        />
    </OuterContainer>
    );
};

export const SFMImageSection = injectIntl(SFMImageSectionComponent);