import React from 'react';
import { injectIntl } from "gatsby-plugin-intl";

import {
    ContactContainer,
    ContactHalf,
    Element,
    OuterContainer,
    Title,
} from './contactSection.styles';

const ContactSectionComponent = ({intl, page}) => {

return(
    <OuterContainer>
      <Element name={`contact`}>
        <Title>{intl.formatMessage({ id: `${page}.contact.title`})}</Title>
      </Element>
        <ContactContainer>
          <ContactHalf>
              Email: <br></br>
              {intl.formatMessage({ id: `contact.email`})}
              <br></br>
              Tel: 
              <br></br>
              {intl.formatMessage({ id: `contact.tel1`})}
              <br></br>
              {intl.formatMessage({ id: `contact.tel2`})}
          </ContactHalf>
          <ContactHalf>
              {intl.formatMessage({ id: `contact.addr.str`})}
              <br></br>
              {intl.formatMessage({ id: `contact.addr.city`})}
              <br></br>
              NIP: {intl.formatMessage({ id: `contact.nip`})}
              <br></br>
              REGON: {intl.formatMessage({ id: `contact.regon`})}
          </ContactHalf>
        </ContactContainer>
    </OuterContainer>
    );
};

export const ContactSection = injectIntl(ContactSectionComponent);