import styled from '@emotion/styled';
import { breakpoints, colors } from '../../styles';
import { H2 } from '../../styledComponents';
import { Element as ElementComponent } from 'react-scroll';

export const OuterContainer = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5% 5% 0;
    margin: 100px 0 0;
`;

export const Title = styled(H2)`
    display: inline-table;
    padding-bottom: 5px;
    border-bottom: none;
    white-space: wrap;
    color: ${colors.brandPrimary};

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom: 4px solid ${colors.brandSecondary};
        white-space: nowrap;
	}
`;

export const Description = styled.p`
    width: 100%;
    padding: 3% 0;
    color: ${colors.brandPrimary};
    font-size: inherit;
    line-height: unset;

    @media (min-width: ${breakpoints.tablet}px) {
        font-size: 20px;
        line-height: 40px;
	}
`;

export const Element = styled(ElementComponent)`
    width: 100%;
`;
