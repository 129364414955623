import facepaint from 'facepaint';

import { breakpoints } from './settings';

export const mq = facepaint(
	[
		breakpoints.mobile,
		breakpoints.tablet,
		breakpoints.tabletBig,
		breakpoints.desktop,
		breakpoints.desktopFull
	].map(bp => `@media (min-width: ${bp}px)`)
);
