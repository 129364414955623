import React from 'react';
import { injectIntl } from "gatsby-plugin-intl";
import { CTA } from '../';
import {
    IconContainer,
    Link,
    OuterContainer,
    Title,
} from './seeMoreSection.styles';

import { MonitorIcon } from '../../images/icons/services/monitor';
import { AlertIcon } from '../../images/icons/services/alert';

const SeeMoreSectionComponent = ({intl, page}) => {

return(
    <OuterContainer>
        <div>
            <Title>{intl.formatMessage({ id: `${page}.seemore.title`})}</Title>
            <Link to={intl.formatMessage({ id: `${page}.seemore.link`})}>
                <CTA 
                    text={intl.formatMessage({ id: `${page}.seemore.cta`})}
                />
            </Link>
        </div>
        <IconContainer>
            {page === 'mobile-alarm-app' && (<MonitorIcon />)}
            {page === 'meeting-app' && (<AlertIcon />)}
        </IconContainer>
    </OuterContainer>
)};

export const SeeMoreSection = injectIntl(SeeMoreSectionComponent);