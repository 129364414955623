import styled from '@emotion/styled';
import { colors } from '../../styles';

const currentPadding = 0;

export const SwitcherContainer = styled.div`
	position: relative;
	box-shadow: ${({ active }) => active && '0px 0px 0px black'};
	margin-left: 20px;

	&:hover {
		cursor: pointer;
	}
`;

export const Current = styled.div`
	display: flex;
	align-items: center;
	padding: ${currentPadding}px;
	margin: -${currentPadding}px;
	// color: ${({ top, isMainPage }) => ( !top && !isMainPage ? 'white' : colors.brandPrimary)};
	color: ${({ top, isMainPage }) => ( !top ? 'white' : colors.brandPrimary)};
	font-weight: bold;
	font-size: ${({ top }) => (!top? '17px' : '15px')};

	& > *:not(:last-of-type) {
		margin-right: 15px;
	}
`;

export const Triangle = styled.div`
	width: 0;
	height: 0;
	border: 4px solid transparent;
	border-bottom: 5px solid ${({ top, isMainPage }) => ( !top ? 'white' : colors.brandPrimary)};
	// border-bottom: 5px solid ${({ top, isMainPage }) => ( !top && !isMainPage ? 'white' : colors.brandPrimary)};
	transform-origin: 50% 75%;
	transition: all 0.3s ease;
	transform: ${({ active }) => (!active? 'translate(-50%, -25%) rotate(180deg)' : 'translate(-50%, -25%)')};
`;

export const Dropdown = styled.div`
	position: absolute;
	background-color: white;
	top: calc(100% + 5px);
	right: 0;
	display: flex;
	flex-direction: column;
	min-width: 100%;
	overflow-y: auto;
	z-index: 1;
	box-shadow: 0 2px 30px 0 rgba(64, 64, 64, .2);
`;

export const Entry = styled.div`
	padding: 10px 20px;
	text-decoration: none;

	&:hover {
		cursor: pointer;
    color: ${colors.brandPrimary};
    background-color: #efefef;
	}
`;
