import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from "gatsby-plugin-intl";

import { FullScreenSection } from '../';

import {
    OuterContainer,
} from './meetingAppHero.styles';

const MeetingAppHeroComponent = ({intl, isSecondaryHero}) => {

    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "meeting-app-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

return(
    <OuterContainer>
        <FullScreenSection
          left
          title={intl.formatMessage({ id: 'meeting-app.hero.title'})}
          desc={intl.formatMessage({ id: 'meeting-app.hero.desc'})}
          cta={intl.formatMessage({ id: 'meeting-app.hero.cta'})}
          alt={intl.formatMessage({ id: 'meeting-app.hero.alt'})}
          fluid={data.placeholderImage.childImageSharp.fluid}
          scroll
          scrollDest={'description'}
          isSecondaryHero={isSecondaryHero}
        />
    </OuterContainer>
    );
};

export const MeetingAppHero = injectIntl(MeetingAppHeroComponent);