import React from 'react';
import { scroller } from 'react-scroll';
import {
    Text,
    TextContainer,
} from './cta.styles';
import { RightArrowIcon } from '../../images/icons'


const CTAComponent = ({ text, down, scrollDest,  ...props }) => {
    return(
        <TextContainer 
            onClick={() => {
                if(down) {
                    scroller.scrollTo(scrollDest, {
                        duration: 400,
                        offset: -70,
                        smooth: 'easeOutExpo'
                    })
                }
            }}
            down={down} 
            {...props}
        >
            <Text>{text}</Text>
            <RightArrowIcon />
        </TextContainer>
    );
};

export const CTA = CTAComponent;