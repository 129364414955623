import React from 'react';
import { CTA } from '../cta/cta';
import {
    Description,
    OuterContainer,
    Img,
    TextContainer,
    Title,
    PrimaryTitle,
} from './fullScreenSection.styles';

const FullScreenSectionComponent = ({fluid, title, desc, cta, alt, left, scroll, scrollDest, isSecondaryHero, noUnderline}) => {

    return(
        <OuterContainer isSecondaryHero={isSecondaryHero}>
            <Img fluid={fluid} alt={alt} isSecondaryHero={isSecondaryHero}/>
            <TextContainer left={left}>
                {isSecondaryHero 
                    ? (<PrimaryTitle noUnderline={noUnderline}>{title} </PrimaryTitle>)
                    : (<Title noUnderline={noUnderline}>{title} </Title>)
                }
                <Description>{desc}</Description>
                {cta && (<CTA text={cta} down={scroll} scrollDest={scrollDest}/>)}
            </TextContainer>
        </OuterContainer>
    );
};

export const FullScreenSection = FullScreenSectionComponent;