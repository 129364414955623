import React from 'react';
import { CTA } from "../";
import {
    Description,
    OuterContainer,
    Img,
    TextContainer,
    Title,
} from './imageSection.styles';

const ImageSectionComponent = ({fluid, title, desc, cta, alt, isLeft}) => {

    return(
        <OuterContainer>
            <Img fluid={fluid} alt={alt} isLeft={isLeft}/>
            <TextContainer>
                <Title>{title} </Title>
                <Description>{desc}</Description>
                {cta && (<CTA text={cta}/>)}
            </TextContainer>
        </OuterContainer>
    );
};

export const ImageSection = ImageSectionComponent;