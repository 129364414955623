import styled from '@emotion/styled';

export const FadeInSectionStyled = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    opacity:  ${({ isVisible }) => (isVisible? '1' : '0')};
    transform: ${({ isVisible }) => (isVisible? 'transform: none;' : 'translateY(20vh)' )};
    visibility: ${({ isVisible }) => (isVisible? 'visible' : 'hidden')};;
    transition: opacity 0.8s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
`;
