import styled from '@emotion/styled';
import { Link as LinkComponent } from 'gatsby-plugin-intl';
import { breakpoints, colors } from '../../styles';
import { H3 } from '../../styledComponents';
import { CTA as CTAComponent } from '../';


export const TileGroup = styled.div`
    width: 100%;
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
    
    @media (min-width: ${breakpoints.desktop}px) {
		justify-content: space-between;
	}
`;

export const TileContainer = styled(LinkComponent)`
    position: relative;
    width: 400px;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px #989898;
    padding: 50px 65px;
    margin: 30px 10px;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    pointer-events: ${({ isActive }) => ( isActive ? `auto` : 'none')};
    cursor:  ${({ isActive }) => ( isActive ? `pointer` : 'default')};;
    
    svg {
        fill: ${colors.brandSecondary};
        width: auto;
        height: 70px;
        margin: 0 0 25px;
    }

    &:hover {
        transform: scale(1.05);
    }
`;

export const Title = styled(H3)`
    padding-bottom: 5px;
    color: ${colors.brandPrimary};

    @media all and (-ms-high-contrast:none) {
        width: 100%;
    }
`;

export const Description = styled.p`
    width: 100%;
    color: ${colors.brandPrimary};
    font-size: 16px;
`;

export const CTA = styled(CTAComponent)`
    position: absolute;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${colors.brandPrimary};
    font-size: 16px;
    text-decoration: none;
    pointer-events: auto;

    @media all and (-ms-high-contrast:none) {
        left: 0;
    }
`;