import styled from '@emotion/styled';
import { colors } from '../../styles';

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 2%;
    // border: 2px solid ${colors.brandPrimary};
    border: 2px solid white;
    border-radius: 50px;
    z-index: 1;
    // color: ${colors.brandPrimary};
    color: white;

    &:hover {
        cursor: pointer;
        // color: ${colors.textWhite};
        // background: ${colors.brandPrimary};
        color: ${colors.brandPrimary};
        background: ${colors.textWhite};
        opacity: 0.7;
    }
`;

export const Text = styled.div`
    width: 100%;
`;